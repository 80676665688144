import type {Period} from "@/bill/Period";

export class Bill {
    constructor(
        private _name: string,
        private _period: Period,
        private _cost: number,
    ) {
    }

    get name(): string {
        return this._name;
    }

    get cost(): number {
        return this._cost;
    }

    get period(): Period {
        return this._period;
    }
}