<template>
  <MainCard
      :title="bill.name"
      :head-btn-icon="faArrowLeft"
      head-route="/"
      bottom-btn="Add payer"
      :bottom-btn-action="onAddPayer"
  >
    <div class="flex flex-col gap-2">

      <div class="flex items-center gap-2 bg-slate-50 px-4 min-h-14 justify-between rounded-md">
        <p class="text-slate-700 text-base font-normal leading-normal flex-1 truncate">Total amount</p>
        <div class="shrink-0"><p class="text-slate-700 text-base font-normal leading-normal">{{ bill.cost }}€</p></div>
      </div>

      <h3 class="text-slate-700 text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">Period</h3>
      <div class="flex items-center gap-2 bg-slate-50 px-4 min-h-[72px] py-2 rounded-md">
        <div class="flex flex-col justify-center">
          <p class="text-slate-700 text-base font-medium leading-normal line-clamp-1">
            {{ bill.period.from.toLocaleDateString() }} - {{ bill.period.to.toLocaleDateString() }}</p>
          <p class="text-slate-500 text-sm italic font-light leading-normal line-clamp-2">{{ bill.period.totalDays() }}
            days</p>
        </div>
      </div>
      <h3 class="text-slate-700 text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">Payers</h3>

      <PayerItem v-for="[key, payer] in payers" :model-value="payer" :key="key" @click="updatePayer(key)"/>

    </div>

  </MainCard>
</template>

<script setup lang="ts">
import MainCard from "@/components/MainCard.vue";
import {useBillStore} from "@/store/billStore";
import type {Bill} from "@/bill/Bill";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import PayerItem from "@/components/PayerItem.vue";
import {Payer} from "@/bill/Payer";
import {useRouter} from "vue-router";
import {usePayersStore} from "@/store/payersStore";
import {onMounted} from "vue";
import {CostSharingService} from "@/bill/CostSharingService";

const billStore = useBillStore();
const payersStore = usePayersStore();
const router = useRouter();

const bill = billStore.getBill as Bill;

let payers = payersStore.getPayers as Map<string, Payer>;

const onAddPayer = () => {
  router.push('/create-payer')
};

const calculateTotals = () => {
  const calculatorService = new CostSharingService(
      bill,
      [...payers.values()],
  );
  calculatorService.calculateCost();
}

onMounted(() => calculateTotals());

const updatePayer = (name: string) => {
  router.push(`/create-payer/${name}`);
}

</script>
