import {defineStore} from "pinia";
import type {Payer} from "@/bill/Payer";

export const usePayersStore = defineStore('payersStores', {
    state: () => ({
        payers: new Map<string, Payer>(),
    }),
    actions: {
        addPayer(payer: Payer) {
            this.payers.set(payer.name, payer);
        },
        updatePayer(key: string, payer: Payer) {
            this.payers.delete(key);
            this.payers.set(payer.name, payer);
        }
    },
    getters: {
        getPayers: (state) => state.payers,
    }
});