import {defineStore} from "pinia";
import type {Bill} from "@/bill/Bill";

export const useBillStore = defineStore('billStore', {
    state: () => ({
        bill: null as Bill | null,
    }),
    actions: {
        setBill(bill: Bill) {
            this.bill = bill;
        }
    },
    getters: {
        getBill: (state) => state.bill,
    }
});