import type {Period} from "@/bill/Period";

export class Payer {

    constructor(
        private _name: string,
        private _periods: Period[],
        private _amount: number|null,
    ) {
    }

    set name(name: string) {
        this._name = name;
    }

    get name(): string {
        return this._name;
    }

    get periods(): Period[] {
        return this._periods;
    }

    get amount(): number|null {
        return this._amount;
    }

    set amount(value: number) {
        this._amount = value;
    }

    totalDays(): number {
        let days = 0;

        for (const period of this._periods) {
            days += period.totalDays();
        }

        return days;
    }
}