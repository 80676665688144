import type {Bill} from "@/bill/Bill";
import type {Payer} from "@/bill/Payer";

export class CostSharingService {

    constructor(
        private readonly bill: Bill,
        private readonly payers: Payer[],
    ) {
        this.guardPeriods();
    }

    public addPayer(payer: Payer): void {
        this.payers.push(payer);
    }

    public calculateCost(): Map<Payer, number>
    {
        const result = new Map<Payer, number>();
        let totalPayersDays = 0;

        for (const payer of this.payers) {
            totalPayersDays += payer.totalDays();
        }

        const pricePerPayerPerDay = parseFloat((this.bill.cost / totalPayersDays).toFixed(6));

        for (const payer of this.payers) {
            let amount = parseFloat((pricePerPayerPerDay * payer.totalDays()).toFixed(2));
            if (isNaN(amount)) {
                amount = 0;
            }

            result.set(payer, amount);
            payer.amount = amount;
        }

        return result;
    }

    private guardPeriods() {
        for (const payer of this.payers) {
            for (const period of payer.periods) {
                if (this.bill.period.isPeriodOutOfBounds(period)) {
                    throw Error(`Period of payer ${payer.name} is out of the bill bound`);
                }
            }
        }
    }
}