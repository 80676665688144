<template>
  <MainCard
      title="New bill"
      :head-btn-icon="faXmark"
      head-route="/home"
      bottom-btn="create"
      :bottom-btn-action="onBillCreated"
  >
    <div class="flex flex-col gap-1">
      <div>
        <label>
          <p class="text-slate-700 text-base font-medium leading-normal pb-2">Name</p>
          <input
              placeholder="Add a title"
              class="w-full min-w-0 resize-none rounded-xl text-[#0d161c] focus:outline-0 focus:ring-0 bg-[#e7eff4] h-14 placeholder:text-slate-700 p-4 text-base font-normal leading-normal"
              :class="{'border-2 border-rose-400': validations.name.$error}"
              v-model="name"
          />
          <span class="text-rose-400 text-sm font-light">{{validations.name.$error ? validations.name.required.$message : '&nbsp;'}}</span>
        </label>
      </div>

      <div>
        <label>
          <p class="text-slate-700 text-base font-medium leading-normal pb-2">Amount</p>
          <input
              placeholder="0.00€"
              class="w-full min-w-0 resize-none rounded-xl text-[#0d161c] focus:outline-0 focus:ring-0 bg-[#e7eff4] h-14 placeholder:text-slate-700 p-4 text-base font-normal leading-normal"
              :class="{'border-2 border-rose-400': validations.amount.$error}"
              v-model="amount"
          />
          <span class="text-rose-400 text-sm font-light">{{validations.amount.$error ? validations.amount.required.$message : '&nbsp;'}}</span>
        </label>
      </div>

      <div>
        <p class="text-slate-700 text-base font-medium leading-normal pb-2">Period</p>
        <DatePicker v-model.range="billPeriod" mode="date" color="sky-blue" expanded>
          <template #footer>
            <div class="flex flex-row gap-4 justify-around mb-2">

              <input
                  placeholder="start"
                  class="w-24 rounded-md text-slate-700 focus:outline-0 focus:ring-0 border-none bg-[#e7eff4] focus:border-none placeholder:text-slate-700 text-center text-base font-normal leading-normal"
                  :value=billPeriod?.start.toLocaleDateString()
                  readonly
              />

              <input
                  placeholder="end"
                  class="w-24 rounded-md text-slate-700 focus:outline-0 focus:ring-0 border-none bg-[#e7eff4] focus:border-none placeholder:text-slate-700 text-center text-base font-normal leading-normal"
                  :value=billPeriod?.end.toLocaleDateString()
                  readonly
              />

            </div>
          </template>
        </DatePicker>
        <span class="text-rose-400 text-sm font-light">{{validations.billPeriod.$error ? validations.billPeriod.required.$message : '&nbsp;'}}</span>
      </div>
    </div>

  </MainCard>
</template>

<script setup lang="ts">

import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";
import MainCard from "@/components/MainCard.vue";
import {DatePicker} from "v-calendar";
import {type Ref, ref} from "vue";
import {DateRange} from "@/models/DateRange";
import {useRouter} from "vue-router";
import {useBillStore} from "@/store/billStore";
import {Bill} from "@/bill/Bill";
import {Period} from "@/bill/Period";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {captureMessage} from "@sentry/vue";

const router = useRouter();
const billStore = useBillStore();

const bill = billStore.getBill as Bill | null;

const rules = {
  name: {required, $autoDirty: true},
  amount: {required},
  billPeriod: {required},
}

const name: Ref<string | undefined> = ref(bill?.name);
const amount: Ref<number | undefined> = ref(bill?.cost);
let billPeriod: Ref<null | DateRange> = ref(null);

const validations = useVuelidate(rules, {
  name,
  amount,
  billPeriod,
});

if (bill?.period) {
  billPeriod = ref(new DateRange(bill.period.from, bill.period.to));
}

const onBillCreated = () => {

  validations.value.$validate();

  if (!name.value || !billPeriod.value || !amount.value) {
    return;
  }


  const bill = new Bill(
      name.value,
      new Period(
          billPeriod.value.start,
          billPeriod.value.end,
      ),
      amount.value,
  );

  captureMessage("Bill Created", {
    extra: bill as unknown as Record<string, unknown>,
  });

  billStore.setBill(bill);
  router.push("/summary");
}

</script>

<style>
.vc-sky-blue {
  --vc-accent-50: #f0f9ff;
  --vc-accent-100: #e0f2fe;
  --vc-accent-200: #bae6fd;
  --vc-accent-300: #7dd3fc;
  --vc-accent-400: #38bdf8;
  --vc-accent-500: #0ea5e9;
  --vc-accent-600: #0284c7;
  --vc-accent-700: #0369a1;
  --vc-accent-800: #075985;
  --vc-accent-900: #0c4a6e;
}
</style>
