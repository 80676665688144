<template>
  <section
      class="max-w-xl bg-white m-auto rounded-2xl shadow-2xl p-5 h-full flex flex-col justify-between"
      style='font-family: "Plus Jakarta Sans", "Noto Sans", sans-serif;'
  >
    <header class="flex flex-row">
      <button
          class="flex-none"
          @click="onBtnHeaderClick"
      >
        <font-awesome-icon size="xl" :icon="headBtnIcon" class="text-slate-700"/>
      </button>
      <h1 class="capitalize flex-1 text-center my-auto text-slate-700 text-lg font-bold leading-tight">{{
          title
        }}</h1>
    </header>
    <div id="content" class="overflow-auto h-full p-2">
      <slot/>
    </div>

    <button
        class="bottom-0 rounded-full w-full h-12 px-5 bg-sky-500 text-slate-50"
        @click="bottomBtnAction"
    >
      <span class="font-bold text-lg leading-tight tracking-tight capitalize">{{ bottomBtn }}</span>
    </button>

  </section>
</template>

<script setup lang="ts">
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useRouter} from "vue-router";

const props = defineProps<{
  title: string,
  headBtnIcon: any,
  headRoute: string,
  bottomBtn: string,
  bottomBtnAction: () => void,
}>()

const router = useRouter();

const onBtnHeaderClick = () => {
  router.push(props.headRoute);
}
</script>