export class DateRange {
    constructor(public start: Date, public end: Date) {
        this.start.setHours(0, 0, 0, 0);
        this.end.setHours(23, 59, 59, 59);
    }

    public toPrimitives(): { start: Date, end: Date } {
        return {start: this.start, end: this.end};
    }

}