<template>
  <div class="flex items-center gap-4 bg-slate-50 px-4 min-h-[72px] py-2 justify-between rounded-md border-2 border-slate-50 hover:border-sky-200 cursor-pointer">
    <div class="flex flex-col justify-center">
      <p class="text-slate-700 text-base capitalize font-semibold leading-normal line-clamp-1">{{payer.name}}</p>
      <p class="text-slate-500 text-sm italic font-light leading-normal line-clamp-2">{{payer.totalDays()}} days</p>
    </div>
    <div class="shrink-0"><p class="text-slate-700 text-base font-normal leading-normal">{{payer.amount}}€</p></div>
  </div>
</template>

<script setup lang="ts">
import type {Payer} from "@/bill/Payer";

const payer = defineModel<Payer>({required: true});
</script>