export class Period {
    constructor(
        private _from: Date,
        private _to: Date,
    ) {
        this._from.setHours(0, 0, 0, 0);
        this._to.setHours(23, 59, 59, 59);
    }

    get from(): Date {
        return this._from;
    }

    get to(): Date {
        return this._to;
    }

    public totalDays(): number {
        const msDiff = this._to.valueOf() - this._from.valueOf();
        const daysDiff = msDiff / (1000 * 60 * 60 * 24);
        return Math.round(daysDiff);
    }

    public isPeriodOutOfBounds(periodInsideBounds: Period): boolean
    {
        return this._from > periodInsideBounds.from ||
            this._to < periodInsideBounds.to;
    }
}