import './assets/main.css'

import {createApp} from 'vue'
import App from './App.vue'
import {setupCalendar} from 'v-calendar';
import 'v-calendar/style.css';
import CreateBill from "@/pages/CreateBill.vue";
import {createMemoryHistory, createRouter} from "vue-router";
import SummaryBill from "@/pages/SummaryBill.vue";
import {createPinia} from "pinia";
import CreatePayer from "@/pages/CreatePayer.vue";
import * as Sentry from "@sentry/vue";

const routes = [
    { path: '/', component: CreateBill },
    { path: '/summary', component: SummaryBill },
    { path: '/create-payer', component: CreatePayer },
    { path: '/create-payer/:name', component: CreatePayer },
];

const router = createRouter({
    history: createMemoryHistory(),
    routes,
})

const pinia = createPinia();

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://a58f39126f3b75ff1e59f1b3e3fd627a@o4507961155452928.ingest.de.sentry.io/4507961157877840",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/www\.paysplit\.app/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(setupCalendar, {})
app.use(router);
app.use(pinia);
app.mount('#app');
