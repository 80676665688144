<template>
  <MainCard
      title="Add payer"
      :head-btn-icon="faArrowLeft"
      head-route="/summary"
      :bottom-btn="isUpdate ? 'update' : 'create'"
      :bottom-btn-action="onAddPayer"
  >
    <div class="flex flex-col gap-4">
      <div>
        <label>
          <p class="text-slate-700 text-base font-medium leading-normal pb-2">Name</p>
          <input
              placeholder="Payer name"
              class="w-full min-w-0 resize-none rounded-xl text-[#0d161c] focus:outline-0 focus:ring-0 bg-[#e7eff4] focus:border-none h-14 placeholder:text-slate-700 p-4 text-base font-normal leading-normal"
              :class="{'border-2 border-rose-400': validations.name.$error}"
              v-model="name"
          />
          <span class="text-rose-400 text-sm font-light">{{validations.name.$error ? validations.name.required.$message : '&nbsp;'}}</span>
        </label>
      </div>

      <div>
        <p class="text-slate-700 text-base font-medium leading-normal pb-2">Select your usage days</p>
        <Calendar
            mode="date"
            :min-date="bill.period.from"
            :max-date="bill.period.to"
            :attributes="attributes"
            @dayclick="dayClick"
            class="order-2"
            color="sky-blue"
            expanded
        >
          <template #footer>
            <span class="mx-3 text-sm font-medium text-gray-900 text-left">Selected days: {{ days.length }}</span>
          </template>
        </Calendar>
      </div>
    </div>

  </MainCard>
</template>

<script setup lang="ts">
import {Calendar} from "v-calendar";
import MainCard from "@/components/MainCard.vue";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {computed, onMounted, type Ref, ref} from "vue";
import {Period} from "@/bill/Period";
import {useBillStore} from "@/store/billStore";
import type {Bill} from "@/bill/Bill";
import {usePayersStore} from "@/store/payersStore";
import {Payer} from "@/bill/Payer";
import {useRoute, useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

const router = useRouter();
const route = useRoute();
const payersStore = usePayersStore();
const bill = useBillStore().getBill as Bill;
const name: Ref<string | undefined> = ref();

interface Day {
  id: string;
  date: Date;
}

const days = ref<Day[]>([]);

const rules = {
  name: {required, $autoDirty: true}
}

const validations = useVuelidate(rules, {
  name,
});

const dates = computed<Date[]>(() => {
  return days.value.map(day => day.date);
});

const payerName = route.params.name as string;

const isUpdate = ref(false);

onMounted(() => {
  if (!route.params.name) {
    return;
  }

  isUpdate.value = true;


  const payer = payersStore.getPayers.get(payerName);

  if (!payer) {
    router.push("/create-payer");
    return;
  }

  name.value = payer.name;
  days.value = payer.periods.map(period => ({
    id: getDateId(period.from),
    date: period.from,
  }));

});

const attributes = computed(() => {
  return dates.value.map(date => ({
    highlight: {
      fillMode: 'solid'
    },
    dates: date,
  }));
}) as unknown as undefined;

const dayClick = (day: Day) => {
  const date = new Date(day.date);

  if (!(date >= bill.period.from && date <= bill.period.to)) {
    return;
  }

  const idx = days.value.findIndex(d => d.id === day.id);
  if (idx >= 0) {
    days.value.splice(idx, 1);
  } else {
    days.value.push({
      id: day.id,
      date: day.date,
    });
  }
}

const onAddPayer = () => {
  validations.value.$validate();

  if (!name.value) {
    return;
  }

  const periods = [];

  for (const day of days.value) {
    const from = new Date(day.date)
    from.setHours(0, 0, 0, 0);

    const to = new Date(day.date)
    to.setHours(23, 59, 59, 0);

    periods.push(new Period(from, to));
  }
  const payer = new Payer(
      name.value,
      periods,
      null,
  );

  if (isUpdate.value) {
    payersStore.updatePayer(payerName, payer);
  } else {
    payersStore.addPayer(payer);
  }
  router.push('/summary');
}

const getDateId = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
</script>
